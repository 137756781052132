<!-- eslint-disable max-len -->
<template>
  <svg viewBox="0 0 2322 1147" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_494_150)">
      <path d="M2321.47 0.0634766H1175.32V1146.21H2321.47V0.0634766Z" fill="#D60A52" />
      <path d="M1146.75 0.0634766H0.608398V1146.21H1146.75V0.0634766Z" fill="#D60A52" />
      <path
        d="M768.614 406.23H910.063V341.563H768.614C632.622 341.563 612.321 424.102 612.321 473.3C612.321 516.578 627.115 550.57 655.455 573.115C627.115 595.685 612.321 629.677 612.321 672.93V804.696H683.831V672.96C683.831 639.792 693.906 605.885 768.604 605.885H910.053V540.375H768.614C693.916 540.375 683.841 506.482 683.841 473.3C683.841 440.117 693.916 406.26 768.614 406.26"
        fill="white"
      />
      <path
        d="M430.161 536.114L353.939 406.23H391.248C465.951 406.23 476.026 440.123 476.026 473.27C476.026 508.762 462.166 528.059 430.161 536.074M547.541 473.27C547.541 424.058 527.245 341.534 391.248 341.534H237.279L353.214 540.33H249.803V804.697H321.313V605.036H390.836L506.045 804.697H586.3L464.237 594.409C518.167 576.035 547.526 533.741 547.526 473.31"
        fill="white"
      />
      <path
        d="M1764.26 852.72C1764.26 808.791 1742.91 773.474 1695.1 773.474C1647.93 773.474 1624.47 808.205 1624.47 852.432C1624.47 896.658 1652.07 928.709 1697.18 928.709C1727.75 928.709 1753.27 912.381 1763.36 883.295L1725.08 877.063C1719.45 888.638 1710.84 896.927 1697.18 896.927C1675.52 896.927 1668.38 876.154 1668.38 857.755H1764.22L1764.26 852.72ZM1669.58 831.947C1671.66 816.806 1680.86 802.559 1697.48 802.559C1713.51 802.559 1723.01 817.104 1725.08 831.947H1669.58Z"
        fill="white"
        fill-opacity="0.6"
      />
      <path
        d="M1361.13 688.87H1404.7V590.45H1467.84V552.516H1404.71V503.279H1472V465.344H1361.13V688.87Z"
        fill="white"
      />
      <path
        d="M1361.13 703.949L1404.7 703.949L1404.71 889.541L1472 889.541L1472 927.476L1361.13 927.476L1361.13 703.949Z"
        fill="white"
        fill-opacity="0.6"
      />
      <path
        d="M1500.09 753.154C1504.96 753.154 1509.71 751.711 1513.76 749.008C1517.8 746.304 1520.96 742.462 1522.82 737.967C1524.68 733.472 1525.17 728.525 1524.22 723.753C1523.27 718.981 1520.93 714.597 1517.49 711.157C1514.05 707.716 1509.66 705.373 1504.89 704.424C1500.12 703.475 1495.17 703.962 1490.68 705.824C1486.18 707.686 1482.34 710.839 1479.64 714.885C1476.93 718.93 1475.49 723.687 1475.49 728.552C1475.48 731.785 1476.11 734.987 1477.35 737.975C1478.58 740.963 1480.39 743.678 1482.68 745.964C1484.96 748.25 1487.68 750.062 1490.67 751.296C1493.66 752.529 1496.86 753.161 1500.09 753.154Z"
        fill="white"
        fill-opacity="0.6"
      />
      <path d="M1520.18 773.474H1480.46V927.417H1520.18V773.474Z" fill="white" fill-opacity="0.6" />
      <path d="M1530.38 465.373H1488.88V688.875H1530.38V465.373Z" fill="white" />
      <path
        d="M1619.94 656.861C1598.59 656.861 1585.56 635.805 1585.56 616.254C1585.56 596.092 1598.61 575.335 1619.94 575.335C1641.28 575.335 1654.32 596.092 1654.32 616.254C1654.32 635.805 1641.28 656.861 1619.94 656.861ZM1619.94 538.582C1574.58 538.582 1543.47 570.9 1543.47 616.254C1543.47 661.305 1574.3 693.613 1619.94 693.613C1665.59 693.613 1696.42 661.305 1696.42 616.254C1696.42 570.9 1665.3 538.582 1619.94 538.582Z"
        fill="white"
      />
      <path
        d="M1887.96 543.328L1857.13 628.424L1821.27 538.605H1801.41L1765.84 627.237L1735.61 543.348H1690.25L1757.25 691.864H1778.6L1813.28 603.232L1844.4 691.864H1865.45L1933.04 543.348L1887.96 543.328Z"
        fill="white"
      />
      <path
        d="M1404.71 261.836H1410.64C1433.76 261.836 1451.25 267.18 1451.25 294.746C1451.25 322.018 1434.37 329.135 1410.34 329.135H1404.71V261.836ZM1404.71 359.665H1405.3L1459.55 449.787H1512.31L1449.18 355.524C1479.12 350.493 1494.83 322.028 1494.83 293.569C1494.83 245.841 1464.3 226.28 1420.13 226.28H1361.13V449.787H1404.71V359.665Z"
        fill="white"
      />
      <path
        d="M1536.91 267.767C1541.78 267.767 1546.53 266.324 1550.58 263.621C1554.63 260.918 1557.78 257.075 1559.64 252.58C1561.5 248.085 1561.99 243.138 1561.04 238.366C1560.09 233.594 1557.75 229.211 1554.31 225.77C1550.87 222.329 1546.48 219.986 1541.71 219.037C1536.94 218.088 1531.99 218.575 1527.5 220.437C1523 222.299 1519.16 225.452 1516.46 229.498C1513.75 233.544 1512.31 238.3 1512.31 243.166C1512.3 246.398 1512.94 249.601 1514.17 252.589C1515.4 255.577 1517.22 258.292 1519.5 260.577C1521.79 262.863 1524.5 264.675 1527.49 265.909C1530.48 267.143 1533.68 267.774 1536.91 267.767Z"
        fill="white"
      />
      <path d="M1557.66 304.237H1516.16V449.784H1557.66V304.237Z" fill="white" />
      <path
        d="M1643.04 336.238C1664.39 336.238 1677.42 357.591 1677.42 377.147C1677.42 396.703 1664.38 417.768 1643.04 417.768C1621.7 417.768 1608.65 396.708 1608.65 377.147C1608.65 357.586 1621.69 336.238 1643.04 336.238ZM1632.07 454.516C1639.71 454.516 1647.26 452.781 1654.14 449.443C1661.02 446.105 1667.06 441.25 1671.79 435.243H1672.39V444.733C1672.39 466.663 1671.79 492.456 1642.44 492.456C1635.29 492.732 1628.31 490.235 1622.95 485.485C1617.59 480.736 1614.28 474.101 1613.7 466.966H1569.82C1571.9 505.799 1607.77 527.446 1643.34 527.446C1697.88 527.446 1713.89 488.309 1713.89 440.885V304.232H1672.39V318.137H1671.79C1667.34 312.381 1661.63 307.71 1655.11 304.475C1648.59 301.24 1641.42 299.526 1634.14 299.46C1590.86 299.46 1566.55 337.996 1566.55 378.021C1566.55 417.143 1589.4 454.496 1632.07 454.496"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1554.94 733.746H1594.67V773.474H1619.5V813.201H1594.67V927.417H1554.94V813.201H1530.12V773.474H1554.94V733.746Z"
        fill="white"
        fill-opacity="0.6"
      />
    </g>
    <defs>
      <clipPath id="clip0_494_150">
        <rect width="2320.86" height="1146.14" fill="white" transform="translate(0.608398 0.0644531)" />
      </clipPath>
    </defs>
  </svg>
</template>
